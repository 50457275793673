import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {getUser} from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../components/Processing/Processing';
import moment from 'moment';
const DriverEdit = () => {
  const {state} = useLocation();
  const [loading, setLoading] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getDriversData();
  }, []);

  const getDriversData = () => {
    setLoading(true);
    formik.setFieldValue('firstname', state?.user?.first_name);
    formik.setFieldValue('lastname', state?.user?.last_name);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('spsv', state?.user?.spsv);
    formik.setFieldValue('icabbiStatus', state?.user?.icabbiStatus);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Please enter first name'),
    lastname: Yup.string().required('Please enter last name'),
    email: Yup.string()
      .email('Please enter a valid email')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        'Email must end with .com',
      )
      .required('Please enter an email'),
    spsv: Yup.string().required('Please enter spsv'),
    icabbiStatus: Yup.string().notRequired(),
    newPass: Yup.string()
      .matches(/^.{8,}$/, 'Password must be a minimum of 8 characters')
      .notRequired(),
    conNewPass: Yup.string()
      .oneOf(
        [Yup.ref('newPass'), null],
        'New password and repeat new password do not match.',
      )
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      icabbiStatus: '',
      spsv: '',
      newPass: '',
      conNewPass: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values?.newPass && !values?.conNewPass) {
        formik.setFieldError('conNewPass', 'Please enter repeat new password');
        return;
      }

      setLoading(true);
      const admin = JSON.parse(getUser());

      console.log('values?.icabbiStatus', values?.icabbiStatus);

      const formData = new FormData();

      formData.append('id', state?.user?.user_id);
      formData.append('first_name', values?.firstname);
      formData.append('last_name', values?.lastname);
      formData.append('email', values?.email);
      formData.append('spsv', values?.spsv);
      formData.append('icabbiStatus', values?.icabbiStatus);
      formData.append('password', values?.conNewPass);

      fetchDataFromAPI(
        'admin/profileUpdate',
        'post',
        formData,
        admin?.authToken,
      )
        .then((res) => {
          setLoading(false);

          toast.success('Driver updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });

          navigate('/driver');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating member: ', error);
        });
    },
  });

  return (
    <>
      <main id="main" className="main">
        {loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/driver">Drivers</Link>
              </li>
              <li className="breadcrumb-item active">
                {state?.user?.first_name} {''} {state?.user?.last_name}
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="name" className="form-label">
                            First Name
                          </label>
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            id="name"
                            value={formik.values.firstname}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'firstname',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.firstname &&
                            formik.errors.firstname && (
                              <div className="text-danger">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            name="lastname"
                            type="text"
                            className="form-control"
                            id="lastname"
                            value={formik.values.lastname}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'lastname',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.lastname &&
                            formik.errors.lastname && (
                              <div className="text-danger">
                                {formik.errors.lastname}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label htmlFor="mobile" className="form-label">
                            Mobile
                          </label>
                          <input
                            disabled
                            name="mobile"
                            type="text"
                            className="form-control"
                            id="mobile"
                            value={state?.user?.mobile_no}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            name="email"
                            type="text"
                            className="form-control"
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'email',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="SPSVLicenceNumber"
                            className="form-label">
                            SPSV Licence Number
                          </label>
                          <input
                            name="SPSVLicenceNumber"
                            type="text"
                            className="form-control"
                            id="SPSVLicenceNumber"
                            value={formik.values.spsv}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'spsv',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.spsv && formik.errors.spsv && (
                            <div className="text-danger">
                              {formik.errors.spsv}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label
                            htmlFor="AgreementVersion"
                            className="form-label">
                            Agreement Version
                          </label>
                          <input
                            disabled
                            name="AgreementVersion"
                            type="text"
                            className="form-control"
                            id="AgreementVersion"
                            value={
                              state?.user?.agreement_version
                                ? state?.user?.agreement_version
                                : '-'
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="DateSigned" className="form-label">
                            Date Accepted
                          </label>
                          <input
                            disabled
                            name="DateSigned"
                            type="text"
                            className="form-control"
                            id="DateSigned"
                            value={
                              state?.user?.agreement_signed &&
                              state.user.agreement_signed !== ''
                                ? moment(state.user.agreement_signed).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )
                                : 'Not Accepted Yet'
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="icabbiStatus" className="form-label">
                            iCabbi
                          </label>
                          <div className="input-group">
                            <select
                              name="icabbiStatus"
                              className="form-select"
                              id="icabbiStatus"
                              // value={formik.values.icabbiStatus}
                              {...formik.getFieldProps('icabbiStatus')}
                              aria-label="Select icabbiStatus">
                              {/* <option value="">Select a icabbiStatus...</option> */}
                              <option value="0">Inactive</option>
                              <option value="1">Active</option>
                              {/* <!-- Add more options as needed --> */}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <label htmlFor="ios/android" className="form-label">
                            iOS/Android
                          </label>
                          <input
                            disabled
                            name="ios/android"
                            type="text"
                            className="form-control"
                            id="ios/android"
                            value={state?.user?.device_type}
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="RegistrationTimestamp"
                            className="form-label">
                            Registration Timestamp
                          </label>
                          <input
                            disabled
                            name="RegistrationTimestamp"
                            type="text"
                            className="form-control"
                            id="RegistrationTimestamp"
                            value={moment(
                              state?.user?.registrationTimestamp,
                            ).format('YYYY-MM-DD HH:mm:ss')}

                            // Adjust the format as needed
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="clicked_to_app"
                            className="form-label">
                            Clicked to App
                          </label>
                          <input
                            disabled
                            name="clicked_to_app"
                            type="text"
                            className="form-control"
                            id="clicked_to_app"
                            value={
                              state?.user?.clicked_to_app
                                ? state?.user?.clicked_to_app
                                : 'No'
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="SignUpComplete"
                            className="form-label">
                            Sign Up Complete
                          </label>
                          <input
                            disabled
                            name="SignUpComplete"
                            type="text"
                            className="form-control"
                            id="SignUpComplete"
                            value={state?.user?.registrationComplete}
                          />
                        </div>
                      </div>

                      {/* <div className="row col-mb-3" /> */}
                      <label>Change Password</label>
                      <hr className="mt-0" />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="newPass" className="form-label">
                            New Password
                          </label>

                          <div className="d-flex">
                            <input
                              name="newPass"
                              type={showNewPass ? 'text' : 'password'}
                              className="form-control"
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="newPass"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'newPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() => setShowNewPass(!showNewPass)}>
                              {showNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                          </div>
                          {formik.touched.newPass && formik.errors.newPass && (
                            <div className="text-danger">
                              {formik.errors.newPass}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="conNewPass" className="form-label">
                            Repeat New Password
                          </label>
                          <div className="d-flex">
                            <input
                              name="conNewPass"
                              type={showConNewPass ? 'text' : 'password'}
                              className="form-control"
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="conNewPass"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'conNewPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() =>
                                setShowConNewPass(!showConNewPass)
                              }>
                              {showConNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                          </div>

                          {formik.touched.conNewPass &&
                            formik.errors.conNewPass && (
                              <div className="text-danger">
                                {formik.errors.conNewPass}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-warning"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                  <div className="tab-pane fade pt-3" id="profile-settings">
                    {/* Settings Form */}

                    {/* End settings Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
    </>
  );
};

export default DriverEdit;
