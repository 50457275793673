import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import constants, { saveUserLocally } from "../../utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import Processing from "../../components/Processing/Processing";
import { toast } from "react-toastify";
import logo from "../../assets/svgs/logo.svg";
import { executeScript } from "../../script";
const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email."),
      password: Yup.string()
        .matches(/^\S*$/, "Password should not contain spaces")
        .required("Please enter your Password."),
    }),

    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Your login logic here using the form values
      // For example, you can authenticate the user and navigate upon successful login
      const { email, password } = values;

      setLoading(true);
      try {
        const body = {
          email: email,
          password: password,
        };

        fetchDataFromAPI("admin/login", "post", body, "")
          .then((response) => {
            toast.success("Login successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });

            saveUserLocally(JSON.stringify(response?.data));
            executeScript();
            navigate("/");
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            console.log("catch error --->:", error);

            if (
              error?.response?.data &&
              typeof error?.response?.data === "string"
            ) {
              setStatus("");
              setSubmitting(false);
              setLoading(false);
              alert(error?.response?.data);
              return;
            }
            setStatus("The login details are incorrect");
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        console.log("catch error: ", error);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
      // Perform authentication logic here...

      // For demonstration purposes, let's assume authentication is successful
      // Redirect the user to the home page
      // localStorage.setItem(constants.USER, email); // Storing user in localStorage
      // navigate('/');
    },
  });

  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER) {
      navigate("/");
    }
  }, []);

  // const btnLogin = () => {
  //   navigate('/');
  // };

  return (
    <main>
      {loading && <Processing />}
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/login"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img src={logo} alt="logo" height={"100%"} width={"100%"} />
                  </Link>
                </div>
                {/* <!-- End Logo --> */}
              </div>
              <div className="col-lg-6">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className=" pb-2">
                      <span class="card-title fs-5 text-center d-none d-lg-block fw-bold">
                        Lynk Driver
                      </span>
                      <h5 class="card-title text-center pb-0">
                        Login to Your Account
                      </h5>
                    </div>

                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      {/*username field*/}
                      <div className="col-12">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            placeholder="Enter an email"
                            type="text"
                            name="email"
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "email",
                                e.target.value?.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            required
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      {/*password field*/}

                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>
                        <div className="d-flex">
                          <input
                            placeholder="Password"
                            type={showPass ? "text" : "password"}
                            name="password"
                            className={`form-control ${
                              formik.touched.password && formik.errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            id="yourPassword"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "password",
                                e.target.value?.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            required
                            style={{
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                          />

                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: "0", // Adjust the radius value
                              borderBottomLeftRadius: "0", // Adjust the radius value
                            }}
                            onClick={() => setShowPass(!showPass)}
                          >
                            {showPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>

                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>

                      {/*submit button*/}
                      <div className="col-12">
                        <button
                          className="btn btn-warning w-100"
                          type="submit"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">Login</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>

                        <div className="text-center mt-3">
                          <Link to={"/forgotpassword"}>Forgot password?</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Login;
