// react
import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// css
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// pages

import Login from "./pages/Login/Login";
import routes from "./routes/routes";
import Error from "./pages/Error/Error";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { ToastContainer } from "react-toastify";
import FormNewPass from "./pages/FormNewPass/FormNewPass";
import { executeScript } from "./script";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    executeScript();
  }, [pathname]);

  return (
    <>
      {pathname !== "/login" &&
        pathname !== "/forgotpassword" &&
        !pathname.includes("/newformpass") &&
        pathname !== "/404" && <Header />}

      {pathname !== "/login" &&
        pathname !== "/forgotpassword" &&
        !pathname.includes("/newformpass") &&
        pathname !== "/404" && <Sidebar />}

      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/newformpass" element={<FormNewPass />} />

        {routes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

export default App;
