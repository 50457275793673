import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import {getUser} from '../../utils/constants';
import Processing from '../../components/Processing/Processing';
import {Button, Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import axios from 'axios';

const ViewUserDetail = () => {
  const {state} = useLocation();
  const params = useParams();

  const user = JSON.parse(getUser());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getDriverDetail, setDriverDetail] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const downloadAttachment = async (attachmentUrl, fileName) => {
    try {
      const response = await fetch(attachmentUrl);
      const blob = await response.blob();

      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute('download', fileName);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const showAttachment = (attachmentUrl) => {
    window.open(attachmentUrl, '_blank');
  };

  useEffect(() => {
    setLoading(true);
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    fetchDataFromAPI(
      `admin/getuser?id=${params?.id}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res', res);
        setDriverDetail(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const showDeleteConfirmation = (id) => {
    setSelectedDriverId(id);
    setShowDeleteModal(true);
  };

  const deleteDrivers = async () => {
    const user = JSON.parse(getUser());
    const params = {
      id: getDriverDetail?.user_id,
    };
    try {
      const response = await axios.delete(`${base_url}admin/deleteUser`, {
        params: params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.authToken}`,
        },
      });
      toast.success('Driver delete successfully ✅', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-success text-white',
      });
      setShowDeleteModal(false);
      navigate('/driver');
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-danger text-white',
      });
      console.log('error', error);
    }
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/driver">Drivers</Link>
            </li>

            <li className="breadcrumb-item active">
              Drivers Detail :- {getDriverDetail?.first_name}{' '}
              {getDriverDetail?.last_name}
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}
              <ul className="nav nav-tabs nav-tabs-bordered">
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview">
                    Overview
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-edit">
                    Documents
                  </button>
                </li>
              </ul>
              <div className="tab-content pt-2">
                <div
                  className="tab-pane fade show active profile-overview"
                  id="profile-overview">
                  <h5 className="card-title mt-3">Drivers Details</h5>
                  <hr />{' '}
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">First Name</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.first_name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Last Name</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.last_name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Email</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.email}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Mobile</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.mobile_no}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">SPSV</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.spsv}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Device</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.device_type}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">
                      Registration Timestamp
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {moment(getDriverDetail?.createdAt).format(
                        'YYYY-MM-DD h:mm A',
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">
                      Clicked to App (Yes/No)
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.clicked_to_app
                        ? getDriverDetail?.clicked_to_app
                        : 'No'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">
                      Agreement Version
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.agreement_version
                        ? getDriverDetail?.agreement_version
                        : '-'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Date Accepted</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.agreement_signed &&
                      getDriverDetail?.agreement_signed !== ''
                        ? moment(getDriverDetail?.agreement_signed).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : 'Not Accepted Yet'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">
                      Sign Up Complete (Yes/No)
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.registrationComplete}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">iCabbi</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.icabbiStatus === 0
                        ? 'Inactive'
                        : 'Active'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Role</div>
                    <div className="col-lg-9 col-md-8">
                      {getDriverDetail?.type}
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirmation(getDriverDetail?.user_id);
                      }}>
                      {!loading && (
                        <span className="indicator-label">Delete Driver</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{display: 'block'}}>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                <div
                  className="tab-pane fade profile-edit pt-3"
                  id="profile-edit">
                  {/* Profile Edit Form */}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Index</th>
                        <th scope="col">Document Name</th>
                        {/* <th scope="col">View</th> */}
                        <th scope="col" className="text-center">
                          Download
                        </th>
                        <th scope="col" className="text-center">
                          View
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getDriverDetail?.attachment &&
                      getDriverDetail.attachment.length > 0 ? (
                        getDriverDetail.attachment.map((item, index) => (
                          <tr key={index}>
                            <th scope="row" className="align-middle">
                              {index + 1}
                            </th>
                            <td className="align-middle">
                              {item?.document_name}
                            </td>

                            <td className="text-center">
                              <button
                                onClick={() =>
                                  downloadAttachment(
                                    item?.document_url,
                                    item?.document_name,
                                  )
                                }
                                className="btn btn-warning">
                                <i className="bi bi-cloud-arrow-down-fill"></i>
                              </button>
                            </td>

                            <td className="text-center">
                              <button
                                onClick={() =>
                                  showAttachment(item?.document_url)
                                }
                                className="btn btn-warning">
                                <i className="bi bi-eye-fill"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No attachments available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
        <Modal
          show={showDeleteModal}
          centered
          onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <p className="m-0">
              Are you sure you'd like to delete this driver and all their
              records?
            </p>
          </Modal.Body>
          <Modal.Footer className="text-end gap-1 border-0 px-3 py-2">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteDrivers()}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </main>
  );
};

export default ViewUserDetail;
