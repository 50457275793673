export const executeScript = () => {
  const platform = detectPlatform();
  // const messageElement = document.getElementById("platform-message");

  if (platform === "Android") {
    // messageElement?.textContent = "You are using an Android device.";
    window.location.replace(
      "intent://driverapp.lynk.ie/#Intent;scheme=https;package=com.lynktaxidriver.app;end"
    );
    // setTimeout(() => {
    //   window.location.href =
    //     "https://play.google.com/store/apps/details?id=com.lynktaxidriver.app";
    // }, 2000);
  }
  //  else if (platform === "iOS") {
  // messageElement?.textContent = "You are using an iOS device.";
  // }
  // else {
  // messageElement?.textContent =
  //   "You are using a web platform (not a mobile device).";
  // window.location.href = "https://driverapp.lynk.ie/";
  // }
};

function detectPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "web";
}
