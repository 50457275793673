import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import constants, {getUser} from '../../utils/constants';
import Processing from '../../components/Processing/Processing';
import {Button, Modal, Pagination} from 'react-bootstrap';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import axios from 'axios';
import * as Yup from 'yup';
import {CSVLink} from 'react-csv';
import fileDownload from 'js-file-download';
import moment from 'moment';

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const [sortByFirstName, setSortByFirstName] = useState('asc');
  const [sortByLastName, setSortByLastName] = useState('asc');

  useEffect(() => {
    getReports(currentPage);
  }, [currentPage, selectedDeviceType]);
  // useEffect(() => {
  //   getReports();
  // }, [selectedDeviceType]);

  const getCsv = async () => {
    const user = JSON.parse(getUser());
    const params = {
      id: user?.user_id,
      // device_type :
      sortOrder: 'DESC',
      // page: currentPage,
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
    };

    try {
      const response = await axios.get(`${base_url}admin/exportSearchReports`, {
        params: params,
        headers: {
          Accept: 'text/csv',
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${user?.authToken}`,
        },
        responseType: 'blob',
      });

      fileDownload(response?.data, 'reports.csv');
    } catch (error) {
      console.log('error', error);
    }
  };

  // get Reports
  const getReports = async (pageNumber) => {
    setLoading(true);
    const user = JSON.parse(getUser());
    // fetchDataFromAPI(
    //   `admin/users?device_type=&page=${pageNumber}&id=${user?._id}`,
    //   'get',
    //   '',
    //   user?.authToken,
    // )
    const params = {
      id: user?.user_id,
      // device_type: 'Android',
      uid: formik.values.id,
      device_type: selectedDeviceType,
      page: pageNumber,
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
    };

    axios({
      url: `${base_url}admin/getAllReports`,
      params: params,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.authToken}`,
      },
    })
      .then((response) => {
        console.log('response', response);
        setReports(response?.data?.data);
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        if (error?.response?.data?.status === 500) {
          localStorage.removeItem(constants.USER);
          navigate('/login');
          toast.error('Internal server error', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
        } else {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('users error: ', error);
        }
      });
  };
  const formik = useFormik({
    initialValues: {
      id: '',
      firstname: '',
      lastname: '',
      mobile: '',
      email: '',
      spsv: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().notRequired(),
      lastname: Yup.string().notRequired(),
      mobile: Yup.string().notRequired(),
      email: Yup.string().notRequired(),
      spsv: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const {firstname, lastname, mobile, email, spsv} = values;
      const user = JSON.parse(getUser());
      getReports(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getReports(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getReports(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // Function to handle sorting by First Name
  const handleSortByFirstName = () => {
    const order = sortByFirstName === 'asc' ? 'desc' : 'asc';
    setSortByFirstName(order);
    setSortByLastName('asc'); // Reset sorting for other columns if needed

    // Perform sorting based on First Name
    const sortedDrivers = [...reports].sort((a, b) => {
      if (order === 'asc') {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the state with the sorted data
    setReports(sortedDrivers);
  };
  // Function to handle sorting by Last Name
  const handleSortByLastName = () => {
    const order = sortByLastName === 'asc' ? 'desc' : 'asc';
    setSortByLastName(order);
    setSortByFirstName('asc'); // Reset sorting for other columns if needed

    // Perform sorting based on Last Name
    const sortedDrivers = [...reports].sort((a, b) => {
      if (order === 'asc') {
        return a.last_name.localeCompare(b.last_name);
      } else {
        return b.last_name.localeCompare(a.last_name);
      }
    });

    // Update the state with the sorted data
    setReports(sortedDrivers);
  };

  const deleteDrivers = async () => {
    const user = JSON.parse(getUser());
    const params = {
      id: selectedDriverId,
    };
    try {
      const response = await axios.delete(`${base_url}admin/deleteUser`, {
        params: params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.authToken}`,
        },
      });
      toast.success('Reports delete successfully ✅', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-success text-white',
      });
      setShowDeleteModal(false);
      getDrivers();
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-danger text-white',
      });
      console.log('error', error);
    }
  };

  const showDeleteConfirmation = (id) => {
    setSelectedDriverId(id);
    setShowDeleteModal(true);
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/managereports">Reports</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/managereports">Manage Reports</Link>
            </li>

            <li className="breadcrumb-item active">
              Incomplete Driver Sign Ups
            </li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3" onSubmit={formik.handleSubmit}>
          <div className="row mb-3"></div>
          <div className="row mb-3 align-items-end">
            <div className="col-md-1">
              <label htmlFor="id" className="form-label">
                ID
              </label>
              <input
                name="id"
                type="text"
                className="form-control"
                id="id"
                onChange={(e) => {
                  formik.setFieldValue('id', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                id="firstName"
                onChange={(e) => {
                  formik.setFieldValue(
                    'firstname',
                    e.target.value?.trimStart(),
                  );
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                id="lastName"
                onChange={(e) => {
                  formik.setFieldValue('lastname', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Mobile" className="form-label">
                Mobile
              </label>
              <input
                name="Mobile"
                type="text"
                className="form-control"
                id="Mobile"
                onChange={(e) => {
                  formik.setFieldValue('mobile', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                name="Email"
                type="text"
                className="form-control"
                id="Email"
                onChange={(e) => {
                  formik.setFieldValue('email', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-1">
              <label htmlFor="spsv" className="form-label">
                SPSV
              </label>
              <input
                name="spsv"
                type="text"
                className="form-control"
                id="spsv"
                onChange={(e) => {
                  formik.setFieldValue('spsv', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2 text-center ">
              <div className="d-flex gap-2 align-items-center">
                <button
                  type="submit"
                  className="btn btn-warning w-100 btn-serach btn-serach">
                  Search
                </button>
                <div role="button" onClick={() => getCsv()}>
                  <img
                    style={{height: 20, width: 25}}
                    src={require('../../assets/images/export.png')}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="card-body">
          <h3 className=" font-weight-bold">Reports List</h3>

          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap align-items-end table-hover">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Id
                  </th>
                  <th
                    scope="col "
                    role="button"
                    onClick={handleSortByFirstName}>
                    First Name {sortByFirstName === 'asc' ? '▲' : '▼'}
                  </th>

                  <th scope="col" role="button" onClick={handleSortByLastName}>
                    Last Name {sortByLastName === 'asc' ? '▲' : '▼'}
                  </th>
                  <th scope="col" className="">
                    Mobile
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    SPSV
                  </th>

                  <th scope="col">Registration Timestamp</th>

                  <th scope="col" className="">
                    <select
                      className="selectapp"
                      value={selectedDeviceType}
                      onChange={(e) => setSelectedDeviceType(e.target.value)}>
                      <option value="">Android/iOS</option>
                      <option value="Android">Android</option>
                      <option value="iOS">IOS</option>
                    </select>
                  </th>
                  <th scope="col" className="">
                    Progress
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports?.map((item, index) => {
                  const formattedDate = moment(item?.createdAt).format(
                    'YYYY-MM-DD HH:mm:ss a',
                  ); // Adjust the format as needed
                  return (
                    <tr>
                      <td scope="row">{item?.user_id}</td>
                      <td className="">{item?.first_name}</td>
                      <td className="">{item?.last_name}</td>
                      <td className="">
                        {item?.mobile_no ? item?.mobile_no : '-'}
                      </td>
                      <td className="">{item?.email}</td>
                      <td className="">{item?.spsv}</td>
                      <td>{formattedDate}</td>
                      <td className="">{item?.device_type}</td>
                      <td className="">{item?.status}</td>

                      <td className="text-center">
                        <div
                          role="button"
                          onClick={() => showDeleteConfirmation(item?.user_id)}
                          className="">
                          <i className="bi bi-trash"></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* <!-- End Default Table Example --> */}
        </div>
        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && ( // Check if it's not the first page
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button">
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages &&
              reports.length > 0 && ( // Check if it's not the last page and there's data
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button">
                  Next
                </p>
              )}
          </Pagination>
        )}

        {totalPages <= 1 && reports?.length === 0 && (
          <p className="text-center">No data available</p>
        )}
      </div>
      <Modal
        show={showDeleteModal}
        centered
        onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="m-0">
            Are you sure you'd like to delete this reports and all their
            records?
          </p>
        </Modal.Body>
        <Modal.Footer className="text-end gap-1 border-0 px-3 py-2">
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => deleteDrivers()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Reports;
